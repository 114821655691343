:root {
  --profit-color: green;
  --profit-text-color: darkgreen;
  --red-text-color: darkred;
}

@media (prefers-color-scheme: dark) {
  :root {
    --profit-color: #00be00;
    --profit-text-color: #00aa00;
    --red-text-color: #ec0000;
  }
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App-Width{
  width: 90%;
  margin: auto;
}

.large{
  font-size: 24px;
}

.medium{
  font-size: 20px;
}

.small{
  font-size: 8px;
}
.badge-dark{
  background-color: #343a40;
}

.badge-info{
  background-color: #2891b1 !important;
}

.badge-secondary{
  background-color: #6c757d !important;
}

.card-title{
  font-size: 24px;
}

.card{
  margin-top: 10px;
}

.top-margin{
  margin-top: 10px;
}

.k-number-badge{
  width: 3rem;
}

.location-type-badge{
  width: 4rem;
}

h2, h3, h4, h5{
  text-align: center;
}

.center-text{
  text-align: center;
}

.middle{
  text-align: center;
  vertical-align: middle;
}

.fnsku-top-pad{
  padding-top: 36px
}

.fnsku-top-alert-pad{
  padding-top: 32px;
}

.font-medium{
  font-size: 24px;
}

.checkbox-medium{
  transform: scale(1.5);
}

.font-large{
  font-size: 32px;
}

.font-size-large-mgmt{
  font-size: 28px;
}

.profit-text{
  color:  var(--profit-text-color);
  font-weight: bold;
}

.red-text{
  color:  var(--red-text-color);
  font-weight: bold;
}

.manual-count{
  font-style: italic;
  font-size: small;
  text-align: right;
}


.table-fixed          { overflow-y: auto; height: 100px; }
.table-fixed thead th { position: sticky; top: 0; background-color: black; }

.form-group-sm {
  margin-bottom: 1px;
}
.form-group-sm input{
  height: 31px;
  width: 60px;
}

h1,h2,h3 {
  vertical-align:middle;
}

h1>.badge, h2>.badge, h3>.badge {
  vertical-align:middle;
}