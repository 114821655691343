.narrow-table{
    max-width: 55em;
    margin: 0 auto;
}

.compactInputGroupPrepend{
    height: 24px;
}

.padding-2px > * > * > tr > * {
    padding: 2px;
    font-size: 10pt;
}



.padding-2px-table  tbody tr td {
    padding: 2px;
    font-size: 10pt;
}

.cell-wrap {
    word-break: break-word;
    white-space: normal !important;
    overflow: hidden;
}

.padding-4px > * > * > tr > * {
    padding: 4px;
    font-size: 10pt;
}

span.input-group-text{
    padding: 4px;
}

.compact{
    padding: 0.2rem;
}

.fill-col-width{

}

/* COMPACT */
table.table.compact {
    margin:0;
    white-space: nowrap;
    width: 1px;
}

/** only for the head of the table. */
table.table.compact thead th {
    padding: 0.6rem;
    white-space: nowrap;
    width: 1px;
    text-align: center;
}

table.table.compact tbody tr th {
    padding: 0.3rem;
    padding-left: 0.6rem;
    white-space: nowrap;
    width: 1px;
    text-align: center;
}

table.table.compact tbody tr td {
    padding:0.3rem;
    padding-left: 0.6rem;
    white-space: nowrap;
    width: 1px;
    text-align: center;
}

/* MD-COMPACT */
table.table.md-compact {
    margin:0;
    white-space: nowrap;
    width: 1px;
}

/** only for the head of the table. */
table.table.md-compact thead th {
    padding: 0.3rem 2rem;
    white-space: nowrap;
    width: 1px;
    text-align: center;
}

table.table.md-compact tbody tr th {
    padding: 0.3rem;
    padding-left: 0.6rem;
    white-space: nowrap;
    width: 1px;
    text-align: center;
}

table.table.md-compact tbody tr td {
    padding:0.3rem;
    padding-left: 0.6rem;
    white-space: nowrap;
    width: 1px;
    text-align: center;
}

/*React Bootstrap Table Compact*/
.react-table-compact{
    padding: 0.3rem;
    padding-left: 0.6rem;
    white-space: nowrap;
    width: 1px;
    text-align: center;
}

.react-table-compact.a{
    padding: 0rem;
}