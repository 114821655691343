.shipping-page{
  max-width: 35rem;
  text-align: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.dirty-pr-row{
    text-decoration: line-through;
    font-style: italic;
    color: rosybrown;
}

.product-name-small {
    font-size: 9pt;
}

.mfn-shipping-input{
    max-width: 70px;
}