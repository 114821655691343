@media (prefers-color-scheme: dark) {
    .btn-outline-secondary{
        color: #e0e0e0 !important;
        background-color: #353535 !important;
    }
    .btn-outline-secondary:hover{
        background-color: #474747 !important;
    }

    .dropdown-item > .nav-link{
        color: #fff !important;
    }
}